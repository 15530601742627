
import { Component, Vue } from 'vue-property-decorator'
import Echarts from 'echarts'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { PolygonPathDetail, PolygonStyle } from '@/types/common'

@Component
export default class WorkCardMap extends Vue {
  private onlineImg = require('@/assets/icon/bracelet/online.svg')
  private onlineImgCursor = require('@/assets/icon/bracelet/online2.svg')
  private offImg = require('@/assets/icon/bracelet/off.svg')
  private offImgCursor = require('@/assets/icon/bracelet/off2.svg')
  private warningImg = require('@/assets/icon/bracelet/sos.svg')
  private warningImgCursor = require('@/assets/icon/bracelet/sos2.svg')
  private projectAreaList: Array<{ projectAreaId: string; areaName: string; list: Array<PolygonPathDetail>; polygon?: Array<AMap.Polygon> }> = []
  private projectAreaId = ''
  // 存储上一个高亮区域id
  private projectAreaIdBak = ''
  private projectPolygon: Array<AMap.Polygon> = []
  private map: AMap.Map | any = null
  private markers: AMap.Marker[] = []
  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#9D4DFF',
    fillColor: '#9D4DFF',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid',
    zIndex: 11
  }

  private polygonStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 10
  }

  private drawer = false
  private drawerData = this.drawerDataBak

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  get drawerDataBak () {
    return {
      workerId: '', // 工人id
      workerName: '', // 工人名称
      deviceId: '',
      deviceStatus: '',
      deviceTypeName: '',
      deviceNumber: '',
      healthStatus: '', // 健康状态
      longitude: '',
      latitude: '',
      collectTime: '',
      presentStatus: '', // 在岗状态
      sosState: '', // sos状态
      fallState: '',
      age: '',
      sex: '',
      identityCard: '', // 身份证
      mobile: '',
      battery: '', // 电量
      batteryStatus: '', // 电量状态
      photo: {
        filePrefix: '',
        fileUrl: ''
      } // 照片地址
    }
  }

  created (): void {
    this.getLocation()
  }

  // 页面离开
  destroyed () {
    if (this.map) {
      this.map.off('click', this.drawerClose())
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaLocation, {
      projectId: this.projectId
    }).then((res) => {
      this.projectAreaList = res.areaLocation || []
      this.loadMap(res.projectInfo, res.projectLocation)
    })
  }

  getData () {
    this.$axios.get(this.$apis.workcard.selectWorkCardList, {
      projectId: this.projectId
    }).then(res => {
      this.onlineEchart(res.presentPie)
      this.healthyEchart(res.healthPie)
      // 打点
      res.list.map((item: {longitude: string; latitude: string; workerName: string}) => {
        const lnglat = [Number(item.longitude), Number(item.latitude)]
        const marker: AMap.Marker = new AMap.Marker({
          icon: new AMap.Icon({
            image: this.getIcon(item, false)
          }),
          offset: new AMap.Pixel(-18, -48),
          position: new AMap.LngLat(lnglat[0], lnglat[1]),
          label: {
            direction: 'top',
            content: '<div class="marker-label-content">' + item.workerName + '</div><div class="marker-label-sharp"></div>'
          },
          extData: item
        })
        marker.on('click', (e: any) => {
          this.clickMarker(e)
        })
        this.map.add(marker)
        this.markers.push(marker)
      })
    })
  }

  // 加载地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<PolygonPathDetail>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      this.$nextTick(() => {
        const map = new AMap.Map('map', {
          center: center,
          zoom: 11
        })
        // 点击关闭抽屉
        map.on('click', this.drawerClose)
        this.projectPolygon = drawPolygon(map, list || [], null, this.drawerClose)
        let style = this.polygonStyle
        this.projectAreaList = this.projectAreaList.map(item => {
          if (this.projectAreaId && item.projectAreaId === this.projectAreaId) {
            style = this.polygonCurrentStyle
          }
          return {
            ...item,
            polygon: item.list ? drawPolygon(map, item.list, style, this.drawerClose) : []
          }
        })
        map.setFitView(this.projectPolygon)
        this.map = map
        this.getData()
      })
    })
  }

  // 点击marker
  clickMarker (e: any) {
    const detail = e.target.getExtData()
    // 判断点击的是否是当前抽屉的设备
    if (this.drawerData.deviceId !== detail.deviceId) {
      if (this.drawer) {
        this.drawerClose()
      }
      this.drawer = true
      this.drawerData = detail
      // 改变图标
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(new AMap.Icon({
        image: this.getIcon(detail, true)
      }))
    }
  }

  // 清除mark
  clearMapMarks () {
    if (this.markers.length > 0) {
      this.map && this.map.remove(this.markers)
      this.markers = []
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 恢复未点击状态图标
      const marker: AMap.Marker[] = this.markers.filter(item => item.getExtData().deviceId === this.drawerData.deviceId)
      if (marker) {
        marker[0].setOffset(new AMap.Pixel(-18, -48))
        marker[0].setIcon(this.getIcon(marker[0].getExtData(), false))
      }
      // 清空抽屉
      this.drawerData = this.drawerDataBak
    }
  }

  // 获取图标 bigShow:是否点击状态
  getIcon (data: any, bigShow: boolean) {
    const cursorIndex = bigShow ? 1 : 0
    let state = 1 // 默认离线
    const icon = [
      [this.onlineImg, this.offImg, this.warningImg],
      [this.onlineImgCursor, this.offImgCursor, this.warningImgCursor] // 点击icon
    ]
    state = data.deviceStatus === '1' ? (data.sosState === '0' && data.runStatus === '0' ? 0 : 2) : 1
    return icon[cursorIndex][state]
  }

  // sos解除
  cancelSos () {
    this.$confirm('是否解除SOS报警?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.workcard.updateCancelSos, {
        workerId: this.drawerData.workerId
      }).then(() => {
        this.$message({
          message: '解除成功',
          type: 'success'
        })
        this.drawerClose()
        // 清除markers
        this.clearMapMarks()
        this.getData()
      })
    })
  }

  // 环形图
  onlineEchart (presentPie: Array<string>) {
    const pieEchart: any = Echarts.init(this.$refs.onlineEcharts as any)
    const option = {
      title: {
        text: '在岗监测',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#1AD1B0', '#975FE4'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在岗' ? `${item}  ${presentPie[0]}` : `${item}  ${presentPie[1]}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '在岗',
          value: presentPie[0]
        }, {
          name: '脱岗',
          value: presentPie[1]
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  // 环形图
  healthyEchart (healthPie: Array<string>) {
    const pieEchart: any = Echarts.init(this.$refs.healthyEcharts as any)
    const option = {
      title: {
        text: '状态监测',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#1AD1B0', '#975FE4'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 30,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '正常' ? `${item}  ${healthPie[0]}` : `${item}  ${healthPie[1]}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '正常',
          itemStyle: {
            normal: {
              color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(111, 178, 254, 1)'
              }, {
                offset: 1,
                color: 'rgba(27, 133, 255, 1)'
              }])
            }
          },
          value: healthPie[0]
        }, {
          name: '异常',
          itemStyle: {
            normal: {
              color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 52, 27, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 109, 106, 1)'
              }])
            }
          },
          value: healthPie[1]
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  areaChange (value: string) {
    // 上一个区域高亮还原
    if (this.projectAreaIdBak) {
      const index = this.projectAreaList.findIndex(item => item.projectAreaId === this.projectAreaIdBak)
      let polygons: Array<AMap.Polygon> = this.projectAreaList[index].polygon || []
      // 按照代码逻辑，只有上一个区域电子围栏确实高亮了才会给projectAreaIdBak赋值，所以不用判断index和list，单如果别的地方随便赋值，也会有影响，所以加入容错
      if (index >= 0 && polygons.length > 0) {
        polygons = polygons.map(item => {
          item.setOptions(this.polygonStyle)
          return item
        })
        this.projectAreaList[index].polygon = polygons
      }
    }
    if (value) {
      const index = this.projectAreaList.findIndex(item => item.projectAreaId === value)
      let polygons: Array<AMap.Polygon> = this.projectAreaList[index].polygon || []
      // 高亮当前区域
      if (index >= 0 && polygons.length > 0) {
        this.projectAreaIdBak = value
        polygons = polygons.map(item => {
          item.setOptions(this.polygonCurrentStyle)
          return item
        })
        this.map.setFitView(polygons)
        this.projectAreaList[index].polygon = polygons
      } else {
        this.projectAreaIdBak = ''
        this.$message.warning('当前区域未设置区域范围地图')
        this.map.setFitView(this.projectPolygon)
      }
    } else {
      this.projectAreaIdBak = ''
      this.map.setFitView(this.projectPolygon)
    }
  }

  // 轨迹回放
  onPlayback () {
    this.$router.push({
      name: 'workcardMapPlayback',
      params: { workerId: this.drawerData.workerId, projectId: this.projectId }
    })
  }

  // 进度条颜色
  getProgressClass (detail: { batteryStatus: string; deviceStatus: string }) {
    const { batteryStatus, deviceStatus } = detail
    if (deviceStatus !== '1' || !batteryStatus) {
      return 'offline'
    } else if (batteryStatus === '1') {
      return 'normal'
    } else if (batteryStatus === '2') {
      return 'low'
    }
  }

  getProgressPer (detail: { battery: string }) {
    return () => {
      if (detail.battery || detail.battery === '0') {
        return `${detail.battery}%`
      }
      return '--'
    }
  }

  getProgressTip (detail: { batteryStatus: string }) {
    const state = ['正常', '电量低']
    if (detail.batteryStatus) {
      return `( ${state[+detail.batteryStatus - 1]} )`
    }
    return ''
  }
}
